<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">ORM</p>
            </div>
            <div class="column is-narrow">
              <div v-if="value.orm"
                class="dropdown is-right is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu-pnet">
                    <span>ORM</span>
                    <span class="icon is-small">
                      <i class="mdi mdi-chevron-down"
                        aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu"
                  id="dropdown-menu-pnet"
                  role="menu">
                  <div class="dropdown-content">
                    <a v-if="isActionEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="sendQuote(ormSendQuoteTypes.Quote)">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-upload mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Quote</span>
                    </a>
                    <a v-if="isSubmitTaxInvoiceEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="sendInvoice(false)">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-currency-usd mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Tax Invoice</span>
                    </a>
                    <a v-if="isActionEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="declineQuoteRequest">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-cancel mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Decline Quote Request</span>
                    </a>
                    <a v-if="isActionEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="sendQuote(ormSendQuoteTypes.TotalLoss)">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-car-off mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Inform Total Loss</span>
                    </a>
                    <a v-if="isActionEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="sendQuote(ormSendQuoteTypes.RequestInspection)">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-eye-check-outline mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Request Inspection</span>
                    </a>
                    <a v-if="isSubmitTaxInvoiceEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="sendInvoice(true)">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-restart mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Re-activate Tax Invoice</span>
                    </a>
                    <hr class="dropdown-divider">
                    <a v-if="$user.info.isSupportUser"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="showUtilities()">
                      <span class="icon is-small is-left has-text-primary"
                        style="self-align: center">
                        <i class="mdi mdi-tools mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Utilities</span>
                    </a>
                    <a href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="viewMessages('ORM')">
                      <span class="icon is-small is-left has-text-primary"
                        style="self-align: center">
                        <i class="mdi mdi-file-code mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">View Messages</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-narrow">
              <table class="table is-bordered is-striped">
                <tr>
                  <td class="has-text-weight-bold has-vertical-middle">Message No.</td>
                  <td v-if="$user.info.isSupportUser">
                    <the-mask class="input"
                      placeholder="Message No."
                      v-model="innerValue.orm.messageNo"
                      :mask="['##']" />
                  </td>
                  <td v-else>{{ value.orm.messageNo }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold has-vertical-middle">ReInstatement No.</td>
                  <td v-if="$user.info.isSupportUser">
                    <the-mask class="input"
                      placeholder="ReInstatement No."
                      v-model="innerValue.orm.reinstatementNo"
                      :mask="['##']" />
                  </td>
                  <td v-else>{{ value.orm.reinstatementNo }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold has-vertical-middle">Quote Status</td>
                  <td v-if="$user.info.isSupportUser">
                    <div class="select">
                      <select v-model="innerValue.orm.quoteStatusId">
                        <option v-for="type in quoteStatusTypes"
                          :key="type.value"
                          :value="type.value">{{ type.name }}</option>
                      </select>
                    </div>
                  </td>
                  <td v-else>{{ quoteStatusTypes.find(s => s.value === value.orm.quoteStatusId).name }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Vehicle ID</td>
                  <td v-if="$user.info.isSupportUser">
                    <input class="input is-capitalized"
                      type="text"
                      placeholder="Vehicle ID"
                      v-model="innerValue.orm.vehicleId"
                      maxlength="20">
                  </td>
                  <td v-else>{{ value.orm.vehicleId }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Underwriter</td>
                  <td>{{ value.orm.underwriter }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Make &amp; Model</td>
                  <td>{{ value.orm.make }} {{ value.orm.model }} {{ value.orm.series }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Quote Type</td>
                  <td>{{ value.orm.quoteType }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Damaged Areas</td>
                  <td>{{ value.orm.damagedAreas }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">QDE Status</td>
                  <td>{{ value.orm.qdeStatus | formatQdeStatus }}</td>
                </tr>
              </table>
            </div>
            <!-- <div class="column">
              <div class="field has-checkradio">
                <div class="control">
                  <input class="is-checkradio is-primary"
                    :class="{ 'has-background-color': innerValue.orm.outOfScope }"
                    id="oufofscope"
                    type="checkbox"
                    name="oufofscope"
                    v-model="innerValue.orm.outOfScope">
                  <label for="oufofscope">Out Of Scope</label>
                </div>
              </div>
            </div> -->
          </div>
        </article>
        <article class="tile is-child box detail-page-tile">
          <p class="title">Remarks</p>
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="filter.pageIndex"
            :page-size="filter.pageSize"
            :total-rows="remarkTotalRows"
            :is-loading="isTableLoading"
            :sort-column="filter.sortColumn"
            :sort-order="filter.sortOrder"
            :show-header="false"
            @pageChanged="onPageChange"
            @sort="sort">
            <tr v-for="(remark, index) in pagedRemarks"
              :key="remark.remarkId"
              @click="highlightSelected(index, $event)">
              <td class="is-content-width">
                <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
              </td>
              <td class="is-remark">
                <div>{{ remark.remarks }}</div>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </article>
      </div>
    </div>
    <xml-viewer-modal v-if="isXmlViewerModalActive"
      :active.sync="isXmlViewerModalActive"
      :quote-id="value.quoteId"
      :message-type="messageType"
      :page-size="10"
      @close="isXmlViewerModalActive = false">
      <p slot="text-title">View {{ messageType }} Messages</p>
    </xml-viewer-modal>
    <orm-decline-quote-request-modal v-if="isOrmDeclineQuoteRequestModalActive"
      :active.sync="isOrmDeclineQuoteRequestModalActive"
      :value="value"
      @close="isOrmDeclineQuoteRequestModalActive = false" />
    <orm-send-quote-modal v-if="isOrmSendQuoteModalActive"
      :active.sync="isOrmSendQuoteModalActive"
      :value="value"
      :send-quote-type="sendQuoteType"
      @close="isOrmSendQuoteModalActive = false" />
    <orm-submit-tax-invoice-modal v-if="isOrmSubmitTaxInvoiceModalActive"
      :active.sync="isOrmSubmitTaxInvoiceModalActive"
      :is-reactivate-invoice="isReactivateInvoice"
      :value="value"
      @sent="reload()" />
    <confirm-modal v-if="isConfirmModalActive"
      :active.sync="isConfirmModalActive"
      @ok="sendQuote(ormSendQuoteTypes.Quote, true)"
      @cancel="isConfirmModalActive = false"
      ok-text="Proceed"
      cancel-text="Cancel">
      <p slot="text-title">Submit Quote</p>
      <p slot="text-content">
        <span class="has-text-weight-bold">
          AUTHORISATION FOR LAST QUOTE NOT YET RECEIVED FROM INSURER. WE STRONGLY RECCOMMEND THAT YOU WAIT FOR THE AUTHORISATION MESSAGE AND LOAD IT INTO THIS QUOTE BEFORE YOU SEND MORE VARIATIONS
        </span>
      </p>
    </confirm-modal>
    <confirm-modal v-if="isWarningModalActive"
      :active.sync="isWarningModalActive"
      @ok="showSubmitTaxInvoiceModal(true)"
      @cancel="isWarningModalActive = false"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Send Invoice</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold"> Some items haven't been authorised. Do you want to proceed? Please note, only the authorised amounts will be sent to the insurer.
        </span>
      </p>
    </confirm-modal>
    <orm-pnet-utilities-modal v-if="isOrmUtilitiesModalActive"
      v-model="innerValue"
      :active.sync="isOrmUtilitiesModalActive" />
  </div>
</template>

<script>
import { QdeStatusTypes, InvoiceTypes, OrmSendQuoteTypes, OrmQuoteStatusTypes, RemarkTypes, EventHubTypes } from '@/enums'
import { XmlViewerModal } from '@/components/XmlViewer'
import { QuoteNotificationMixin, QuoteAssessmentMixin } from './mixins'
import { OrmDeclineQuoteRequestModal, OrmSendQuoteModal, OrmSubmitTaxInvoiceModal } from './components'
import QuoteStoreMixin from './mixins/QuoteStoreMixin'
import { ConfirmModal } from '@/components/BulmaModal'
import { RemarkColumns } from './columns'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import _cloneDeep from 'lodash/cloneDeep'
import { OrmService } from '@/services'
import OrmPnetUtilitiesModal from './components/OrmPnetUtilitiesModal'
import { TheMask, mask } from 'vue-the-mask'
import OrmMessageService from '../ormmessage/OrmMessageService'

export default {
  name: 'QuoteOrm',
  inject: ['$vv'],
  directives: { mask },
  components: {
    XmlViewerModal,
    OrmDeclineQuoteRequestModal,
    OrmSendQuoteModal,
    ConfirmModal,
    BulmaTable,
    OrmSubmitTaxInvoiceModal,
    OrmPnetUtilitiesModal,
    TheMask
  },
  filters: {
    formatQdeStatus(value) {
      if (value && QdeStatusTypes.hasOwnProperty(value)) {
        return QdeStatusTypes[value]
      } else {
        return ''
      }
    }
  },
  mixins: [QuoteNotificationMixin, QuoteStoreMixin, DateTimeFiltersMixin, QuoteAssessmentMixin],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: null,
      messageType: 'ORM',
      isXmlViewerModalActive: false,
      isOrmDeclineQuoteRequestModalActive: false,
      isOrmSendQuoteModalActive: false,
      sendQuoteType: OrmSendQuoteTypes.Quote,
      isConfirmModalActive: false,
      isWarningModalActive: false,
      isSendQuoteAnyway: false,
      isOrmSubmitTaxInvoiceModalActive: false,
      filter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 8
      },
      isTableLoading: false,
      isReactivateInvoice: false,
      quoteStatusTypes: null,
      isOrmUtilitiesModalActive: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    qdeStatusTypes() {
      return QdeStatusTypes
    },
    isActionEnabled() {
      return this.value.orm && this.value.orm.messageNo
    },
    isSubmitTaxInvoiceEnabled() {
      return this.value.invoices.some((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted)
    },
    ormSendQuoteTypes() {
      return OrmSendQuoteTypes
    },
    columns() {
      return RemarkColumns
    },
    remarkTotalRows() {
      return this.value.otherRemarks.filter((r) => r.remarkTypeId === RemarkTypes.ORMSystemRemark && !r.isDeleted).length
    },
    activeRemarks() {
      return this.value.otherRemarks.filter((r) => r.remarkTypeId === RemarkTypes.ORMSystemRemark && !r.isDeleted)
    },
    pagedRemarks() {
      return this.activeRemarks.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    }
  },
  watch: {
    // 'innerValue.orm.outOfScope': function(newVal, oldVal) {
    //   this.$emit('input', newVal)
    // }
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.getOrmQuoteStatusTypes()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    viewMessages(type) {
      this.messageType = type
      this.isXmlViewerModalActive = true
    },
    async sendQuote(type, sendAnyway = false) {
      const hasPenndingMessage = await OrmMessageService.hasAnyPendingOrmMessage(this.innerValue.quoteId)
      if (hasPenndingMessage) {
        this.$notification.openNotificationWithType('danger', 'ORM', 'There are pending messages. Please load those messages before sending quote.')
        return
      }
      this.$v.ormHeaderGroup.$touch()
      this.$v.ormSummaryGroup.$touch()
      if (this.$v.ormHeaderGroup.$error || this.$v.ormSummaryGroup.$error) {
        this.$notification.openNotificationWithType('danger', 'ORM', 'Send cancelled. Required fields are missing or invalid')
        return
      }
      if (!this.snapshotDiff) {
        if (this.value.orm.quoteStatusId === OrmQuoteStatusTypes.QuoteSubmitted && type === OrmSendQuoteTypes.Quote && !sendAnyway) {
          this.isConfirmModalActive = true
        } else {
          this.isConfirmModalActive = false
          this.sendQuoteType = type
          this.isOrmSendQuoteModalActive = true
        }
      } else {
        // this.$notification.openNotificationWithType('warning', 'ORM', `Quote has changed please save before continuing`)
        this.showQuoteChangedToast()
      }
    },
    declineQuoteRequest() {
      if (!this.snapshotDiff) {
        this.isOrmDeclineQuoteRequestModalActive = true
      } else {
        this.showQuoteChangedToast()
      }
    },
    async sendInvoice(isReactivateInvoice) {
      this.isReactivateInvoice = isReactivateInvoice
      const items = [...this.value.labours, ...this.value.others, ...this.value.parts, ...this.value.opgs, ...this.value.miscs, ...this.value.sublets]
      const itemsChanged = this.itemsChanged(items, this.value.quotingMethod)
      if (this.innerValue.quoteAssessments.some((a) => a.isInvoiced !== true)) {
        this.isWarningModalActive = true
      } else if (itemsChanged.length > 0) {
        this.isWarningModalActive = true
      } else  {
        await this.showSubmitTaxInvoiceModal()
      }
    },
    async showSubmitTaxInvoiceModal() {
      this.isWarningModalActive = false
      const hasPenndingMessage = await OrmMessageService.hasAnyPendingOrmMessage(this.innerValue.quoteId)
      if (hasPenndingMessage) {
        this.$notification.openNotificationWithType('danger', 'ORM', 'There are pending messages. Please load those messages before sending quote.')
        return
      }
      this.$v.ormHeaderGroup.$touch()
      this.$v.ormSummaryGroup.$touch()
      if (this.$v.ormHeaderGroup.$error || this.$v.ormSummaryGroup.$error) {
        this.$notification.openNotificationWithType('danger', 'ORM', 'Send cancelled. Required fields are missing or invalid')
        return
      }
      if (!this.snapshotDiff) {
        this.isOrmSubmitTaxInvoiceModalActive = true
      } else {
        this.showQuoteChangedToast()
      }
    },
    reload() {
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.showSuccessToast('Tax invoice is being submitted')
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    sort(name, order) {},
    async getOrmQuoteStatusTypes() {
      this.quoteStatusTypes = await OrmService.getOrmQuoteStatusTypes()
    },
    showUtilities() {
      this.isOrmUtilitiesModalActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
a.dropdown-item-has-icon {
  display: flex;
  align-items: center;
}
span.dropdown-item-text {
  padding-left: 5px;
}
</style>
